<template>
  <div class="organizations">
    <a-row :gutter="30">
      <a-col :span="showBlock ? 18 : 24">
        <template v-if="showBlock">
          <div class="switch-region">
            <b>类型: </b>
            <router-link
              :to="item.path"
              v-for="item in menu"
              :key="item.path"
              >{{ item.meta.title }}</router-link
            >
          </div>
          <div class="switch-region">
            <b>区县: </b>
            <span @click="region = null" :class="region == null ? 'active' : ''"
              >全部</span
            >
            <span
              @click="region = item.code"
              :class="region == item.code ? 'active' : ''"
              v-for="item in regions"
              :key="item.code"
              >{{ item.name }}</span
            >
          </div>
        </template>
        <router-view :region="region" :keyword="keyword"></router-view>
      </a-col>
      <a-col v-if="showBlock" :span="6">
        <div class="search">
          <input
            type="text"
            placeholder="请输入要搜索的内容"
            v-model="searchKeyword"
            name="keyword"
            value=""
          />
          <a-button type="primary" @click="keyword = searchKeyword"
            >搜索</a-button
          >
        </div>
        <div class="hot-list">
          <div class="title">热门推荐</div>
          <div class="item" v-for="(item, index) in hot" :key="index">
            <router-link
              :to="{
                name: 'village_organization_detail',
                params: { id: item.villageId },
              }"
              >{{
                item.organizationName ||
                item.village.countryName +
                  item.village.villageName +
                  item.principalName
              }}</router-link
            >
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script type="text/ecmascript-6">
import { menus } from "@/router/routes";
import Api from "@/api/organization";
const regions = [
  { name: "大通区", code: "340402000000" },
  { name: "田家庵区", code: "340403000000" },
  { name: "谢家集区", code: "340404000000" },
  { name: "八公山区", code: "340405000000" },
  { name: "潘集区", code: "340406000000" },
  { name: "凤台县", code: "340421000000" },
  { name: "寿县", code: "340422000000" },
];
export default {
  data() {
    return {
      region: null,
      hot: [{}],
      regions,
      searchKeyword: "",
      keyword: "",
    };
  },
  computed: {
    menu() {
      const m = this._.filter(menus, (item) => item.path == "/organizations");
      return this._.filter(m[0].children, (item) => item.meta.menu);
    },
    showBlock() {
      return this.$route.path.indexOf("detail") < 0;
    },
  },
  components: {},
  created() {
    this.hotRecommend();
  },
  methods: {
    search() {},
    hotRecommend() {
      Api.getHotRecommend().then((res) => (this.hot = res));
    },
  },
};
</script>

<style scoped lang="scss">
.switch-region {
  line-height: 30px;
  color: #444;
  margin-bottom: 10px;
  span,
  a {
    cursor: pointer;
    display: inline-block;
    padding: 0 10px;
    text-align: center;
    margin-left: 10px;
    &.active,
    &.router-link-exact-active {
      background: #008840;
      color: white;
    }
  }
}
.organizations {
  .search {
    border-bottom: 2px solid #008840;
    padding-bottom: 10px;
  }
  .hot-list {
    text-align: center;
    line-height: 2em;
    color: #333;
    font-weight: 500;
    .title {
      line-height: 3em;
    }
    a {
      color: #333;
    }
  }
}
</style>
