import request from "@/utils/request"

export default {
    newFive(params) {
        return request.get("/chems/getLatestChemslist", {
            params
        });
    },
    clickFive(params) {
        return request.get("/chems/getMostHitsChemslist", {
            params
        });
    },
    list(params) {
        return request.get("/chems/list", {
            params
        });
    },
    click(chemsName) {
        return request.post("/chems/hitsCurrentChems?chemsName=" + chemsName)
    },
    detail(chemsId) {
        return request.get(`/chems/${chemsId}`)
    },
    newFiveVillage(params) {
        return request.get("/village/getLatestVillagelist", {
            params
        });
    },
    clickFiveVillage(params) {
        return request.get("/village/getMostHitsVillagelist", {
            params
        });
    },
    listVillage(params) {
        return request.get("/village/list", {
            params
        });
    },
    clickVillage(organizationName) {
        return request.post("/village/hitsCurrentOrganizationName?organizationName=" + organizationName)
    },
    getHotRecommend() {
        return request.get("/getHotRecommend")
    },
    villageDetail(villageInfoId) {
        return request.get(`/village/${villageInfoId}`)
    },
    getChemsImagesFiles(params = {}) {
        return request.post("/file/getChemsImagesFiles", 
            params
        );
    },
    getVillageImagesFiles(params = {}) {
        return request.post("/file/getVillageImagesFiles", 
            params
        );
    },
}